<template>
  <div>
    <div @click="showDetail = true">
      <h4 class="text-secondary">
        <strong>{{ $t("courseCreation.title") }}</strong>
      </h4>
      <p>{{ $t("courseCreation.description") }}</p>
    </div>

    <Modal hide-footer v-model="showDetail">
      <h5 class="mt-3 text-primary">{{ $t("courseCreation.step1.title") }}</h5>

      <p class="px-3">{{ $t("courseCreation.step1.description") }}</p>

      <ul class="px-3">
        <li>{{ $t("courseCreation.step1.list.topic") }}</li>
        <li>{{ $t("courseCreation.step1.list.targetAudience") }}</li>
        <li>{{ $t("courseCreation.step1.list.learningObjectives") }}</li>
        <li>{{ $t("courseCreation.step1.list.subjectScope") }}</li>
        <li>{{ $t("courseCreation.step1.list.contentFormat") }}</li>
      </ul>

      <h5 class="mt-3 text-primary">{{ $t("courseCreation.step2.title") }}</h5>
      <p class="px-3">{{ $t("courseCreation.step2.description") }}</p>

      <h5 class="mt-3 text-primary">{{ $t("courseCreation.step3.title") }}</h5>
      <p class="px-3">{{ $t("courseCreation.step3.description") }}</p>

      <h5 class="mt-3 text-primary">{{ $t("courseCreation.step4.title") }}</h5>
      <p class="px-3">{{ $t("courseCreation.step4.description") }}</p>

      <ul class="px-3">
        <li>{{ $t("courseCreation.step4.list.useMedia") }}</li>
        <li>{{ $t("courseCreation.step4.list.answerQuestions") }}</li>
        <li>{{ $t("courseCreation.step4.list.requestFeedback") }}</li>
      </ul>

      <p class="px-3">{{ $t("courseCreation.conclusion") }}</p>
    </Modal>
  </div>
</template>

<script>
import Modal from "../Shared/Modal/index.vue";
export default {
  components: {
    Modal,
  },
  data() {
    return {
      showDetail: false,
    };
  },
};
</script>

<style></style>
