import { mapGetters } from "vuex";
import Modules from "./Modules";
export default {
  mixins: [Modules],
  computed: {
    ...mapGetters(["getFileSize", "getLoadingOfFileSize"]),
  },
  methods: {
    CONVERT_TO_GB(n) {
      return (n / Math.pow(10, 9)).toFixed(1) + " GB";
    },
    FIND_PERCENT(n) {
      const totalSize = 1000000000 * Number(this.storageModule.value);
      if (this.storageModule.value == 0) return 0;
      return Math.round((n * 100) / totalSize);
    },
    RETURN_CLASS() {
      const percent = this.FIND_PERCENT(this.getFileSize.usedSize);
      if (percent < 50) return "bg-success";
      else if (percent < 90) return "bg-warning";
      else return "bg-danger";
    },
  },
};
