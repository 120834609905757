<template>
  <Modal
    v-model="editSection"
    @ok="SEND_TO_ADD_SECTION"
    ref="editSectionModal"
    :title="$t('addNewSection')"
  >
    <section class="vid-title-sec rounded">
      <div class="container">
        <h5 class="text-primary">{{ $t("addSectionTitle") }}</h5>
        <p class="mt-1 mb-4">
          {{ $t("addSectionDescription") }}
        </p>
        <div
          class="alert"
          :class="`alert-${alertBox.variant}`"
          v-if="alertBox.status"
        >
          {{ alertBox.message }}
        </div>
        <form @submit.prevent="SET_SUBMIT_SECTION">
          <div class="vid-title">
            <h2 class="title-hd">{{ $t("sectionName") }}</h2>
            <div class="form_field">
              <input
                type="text"
                maxlength="100"
                v-model="form.name"
                :placeholder="$t('addHere')"
              />
            </div>
          </div>
        </form>
      </div></section
  ></Modal>
</template>

<script>
import Modal from "../Shared/Modal/index.vue";
export default {
  components: { Modal },
  data() {
    return {
      editSection: false,
      form: { id: -1, name: "" },
      alertBox: { status: false, message: "", variant: "success" },
    };
  },
  props: {
    product: {
      default: () => {},
    },
    productIndex: {
      default: -1,
    },
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.SET_CATEGORY_ACTION_FROM_QUERY();
      },
    },
    editSection(data) {
      if (!data)
        this.$router.replace({
          query: { ...this.$route.query, modal: "closed" },
        });
    },
  },
  methods: {
    SET_SUBMIT_SECTION() {
      this.SEND_TO_ADD_SECTION(this.$refs.editSectionModal.SET_IS_LOADING);
    },
    async SEND_TO_ADD_SECTION(loading) {
      loading(true);
      this.alertBox = await this.$store.dispatch(
        "SEND_ADD_SECTION_OF_PRODUCT",
        {
          productIndex: this.productIndex,
          form: {
            name: this.form.name,
            productId: this.product.id,
            type: this.product.type,
          },
        }
      );
      loading(false);
      this.editSection = false;
      setTimeout(() => {
        if (this.alertBox.variant === "success")
          this.$store.commit("SET_PRODUCT_IS_LOADING", true);
        setTimeout(() => {
          setTimeout(() => {
            this.$store.commit("SET_PRODUCT_IS_LOADING", false);
          }, 100);
        }, 2500);
      }, 150);
    },
    SET_CATEGORY_ACTION_FROM_QUERY() {
      if (this.$route.query.modal)
        if (this.$route.query.modal === "add-section") {
          this.editSection = true;
        } else this.editSection = false;
    },
  },
  created() {
    this.SET_CATEGORY_ACTION_FROM_QUERY();
  },
};
</script>
