<template>
  <Modal
    v-model="editSection"
    @ok="SEND_TO_UPDATE_SECTION"
    ref="editSectionModal"
    :title="$t('updateSection')"
    :hideFooter="confirmRemoveSection"
    :sm="confirmRemoveSection"
  >
    <section v-if="confirmRemoveSection">
      <p>{{ $t("youWillRemoveSection") }}</p>
      <div class="mt-4 text-right">
        <button class="btn btn-pill" @click="confirmRemoveSection = false">
          {{ $t("cancel") }}
        </button>
        <button
          @click="SEND_TO_REMOVE_SECTION"
          :disabled="isLoadingRemoveSection"
          class="btn btn-pill btn-danger"
        >
          <loading v-if="isLoadingRemoveSection"></loading>
          <span v-else>{{ $t("yesRemoveSection") }}</span>
        </button>
      </div>
    </section>
    <section v-else class="vid-title-sec">
      <div class="container">
        <div v-if="!isLoadingRemoveSection">
          <div class="bg-light card">
            {{ $t("updateSectionHint") }}
            <div class="text-right mt-2">
              <button
                @click="confirmRemoveSection = true"
                class="btn btn-pill btn-danger"
              >
                {{ $t("removeSection") }}
              </button>
            </div>
          </div>
          <hr />
        </div>
        <div
          class="alert"
          :class="`alert-${alertBox.variant}`"
          v-if="alertBox.status"
        >
          {{ alertBox.message }}
        </div>
        <form @submit.prevent="SET_SUBMIT_SECTION">
          <div class="vid-title">
            <h2 class="title-hd">{{ $t("sectionName") }}</h2>
            <div class="form_field">
              <input
                type="text"
                maxlength="100"
                v-model="form.name"
                :placeholder="$t('addHereMax100Char')"
              />
            </div>
          </div>
        </form>
      </div></section
  ></Modal>
</template>

<script>
import Modal from "../Shared/Modal/index.vue";
export default {
  components: { Modal },
  data() {
    return {
      confirmRemoveSection: false,
      isLoadingRemoveSection: false,
      editSection: false,
      form: { id: -1, name: "" },
      alertBox: { status: false, message: "", variant: "success" },
    };
  },
  props: {
    sections: {
      default: () => [],
    },
    productIndex: {
      default: -1,
    },
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.SET_CATEGORY_ACTION_FROM_QUERY();
      },
    },
    editSection(data) {
      if (!data)
        this.$router.replace({
          query: { ...this.$route.query, modal: "closed" },
        });
    },
  },
  methods: {
    SET_SUBMIT_SECTION() {
      this.SEND_TO_UPDATE_SECTION(this.$refs.editSectionModal.SET_IS_LOADING);
    },
    async SEND_TO_UPDATE_SECTION(loading) {
      loading(true);
      this.alertBox = await this.$store.dispatch(
        "SEND_UPDATE_SECTION_OF_PRODUCT",
        {
          productIndex: this.productIndex,
          sectionIndex: this.sections.findIndex((s) => s.id === this.form.id),
          form: this.form,
        }
      );
      loading(false);
      setTimeout(() => {
        if (this.alertBox.variant === "success")
          this.$store.commit("SET_PRODUCT_IS_LOADING", true);
        setTimeout(() => {
          this.$store.commit("SET_PRODUCT_IS_LOADING", false);
        }, 1000);
      }, 2500);
    },
    SET_CATEGORY_ACTION_FROM_QUERY() {
      if (this.$route.query.modal)
        if (this.$route.query.modal.includes("section")) {
          const [, id] = this.$route.query.modal.split("-");
          const form = this.sections.find((s) => s.id === Number(id));
          if (form) {
            this.form = { ...form };
            this.editSection = true;
          } else this.editSection = false;
        }
    },
    async SEND_TO_REMOVE_SECTION() {
      this.isLoadingRemoveSection = true;
      this.alertBox = await this.$store.dispatch("SEND_TO_REMOVE_SECTION", {
        id: this.form.id,
        sectionIndex: this.sections.findIndex((s) => s.id === this.form.id),
        productIndex: this.productIndex,
      });
      this.confirmRemoveSection = false;
      setTimeout(() => {
        if (this.alertBox.variant === "success")
          this.$store.commit("SET_PRODUCT_IS_LOADING", true);
        setTimeout(() => {
          this.$store.commit("SET_PRODUCT_IS_LOADING", false);
          this.isLoadingRemoveSection = false;
        }, 1000);
      }, 2500);
    },
  },
  created() {
    this.SET_CATEGORY_ACTION_FROM_QUERY();
  },
};
</script>
