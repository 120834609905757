<template>
  <div class="invalid-feedback d-block">{{ description }}</div>
</template>

<script>
export default {
  props: {
    description: {
      default: function () {
        return this.$t("fieldIsRequired");
      },
    },
  },
};
</script>

<style></style>
